
// Fonts
//@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans&display=swap');

// Bootstrap


/**
 * VENDORS
 * =======================================================
 */

@import '~bootstrap/scss/bootstrap';
@import "~@fortawesome/fontawesome-free/css/all";

@import 'common/variables';
@import 'common/defaults';

// Colors
@import 'common/colors';
@import './auth/password';


.card {
  border-color: $white;
}

.locale {
  color: $primary;
}

.btn-auth {
  background: $primary !important;
  color: $white !important;
  text-transform: uppercase;
  font-weight: 700;
}

label {
  font-weight: 700;
  font-size: 13px;
}

.show-password-action {
  cursor: pointer;
  right: 6px;
  top: 7px;
}

button {
  font-size: $font-size-mini !important;
}

