
// defaults classes

body {
  font-family: $font-family-sans-serif !important;
}

.text-mini {
  font-size: $font-size-mini !important;
}

.card-help {
  background: #f1f1f1;
  border-radius: 4px;
}

.btn-primary {
  background: $black;
  border-color: $black;
}

.btn-primary:hover {
  background: $black;
  border-color: $black;
}